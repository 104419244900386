module.exports = [{
      plugin: require('/Users/praveenkumarsaini/Documents/learn/praveen-me.github.io/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/praveenkumarsaini/Documents/learn/praveen-me.github.io/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/praveenkumarsaini/Documents/learn/praveen-me.github.io/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-120510216-1","head":true},
    },{
      plugin: require('/Users/praveenkumarsaini/Documents/learn/praveen-me.github.io/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
